import { useApolloClient } from "@apollo/client";
import { navigate } from 'gatsby';
import { useEffect } from "react";
import { useAppDispatch } from "../app/hooks";
import { logout } from "../global/state/user-slice";
import { logOut } from "../services/token-storage";

const LogoutPage = ({location}:any) => {
    let globalState = useApolloClient();
    const dispatch = useAppDispatch();

    useEffect(() => {
        // Firstly clear the ID token.
        logOut();
        // Then clear the user.
        dispatch(logout());
        
        // Then clear the store.
        if(typeof window !== `undefined`) {
            window.localStorage.clear()
        }
        globalState.resetStore()
        if(location.state.prevPath) {
            if(typeof window !== `undefined`) {
                window.localStorage.setItem('prevPath', location.state.prevPath);
            }
        }
        if(typeof window !== `undefined`) {
            navigate('/',{replace:true})
        }
    },[])
    return null
}

export default LogoutPage
